.features-section-one-container {
  padding: 30px 0;
}

.features-section-two-container {
  height: 100%;
  background-color: #f8f8f8;
  text-align: center;
  padding: 20px 0;
}

.features-section-two-container.icon-container {
  height: 285px;
  width: 250px;
  border-radius: 20px;
  padding: 20px;
}

.features-section-three-container {
  margin: 30px 0;
}

.mycard span {
  font-size: 18px;
  font-weight: 800 !important;
}
