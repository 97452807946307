* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primery: #212b36;
  --secondery: #00a1e5;
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c3c3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.breadcrumbHeading {
  /* margin: 50px 0 ; */
  margin-bottom: 50px;
  padding: 15px;
  background-color: #5a5b5c;
}

.breadcrumbHeading a {
  color: #fff !important;
}

.MuiMenu-list {
  max-height: 200px;
}

.freeAccount .MuiFormLabel-root {
  background: white;
  padding: 0 6px;
}

.accountForm {
  display: flex;
  flex-direction: column;
}

.memberShipCard {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
}

.memberShipCard table {
  width: 100%;
}

.memberShipCard td {
  padding-top: 12px;
}

.memberShipCard th {
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 12px;
}

.features {
  margin-top: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  /* -moz-appearance: "textfield" ; */
}

.welcomePage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  height: 120vh;
  top: -19px;
  left: 0;
  z-index: 9999999999;
}

.messageCard {
  position: relative;
}

.welcomePage .messageCard {
  width: 60%;
  text-align: center;
}

.cardText {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.messageCard img {
  width: 300px;
  margin-bottom: 60px;
}

.cardText small {
  margin-top: 10px;
}
