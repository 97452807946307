

.tearmsOfServiceHeading{
    margin: 0 0 40px 0 ;
    padding: 15px;
    background-color: #5A5B5C !important;
}

.inneritem p {
    margin-bottom: 20px;
    line-height: 1.5;
    text-align: justify;
}
.inneritem h5,ul{
    margin-bottom: 20px;
}
