#breathing-button {
  width: 100%;
  padding: 10px;
  margin: 20px auto;
  /* border: 1px solid #d1d1d1; */
  -webkit-animation: breathing 7s ease-out infinite normal;
  animation: breathing 7s ease-out infinite normal;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  border-radius: 50px;
  text-align: center;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@media screen and (max-width: 768px) {
  #breathing-button {
    /* width: 100%;
    overflow-x: 'scroll'; */
    display: none;
  }
}
