.headingBox a {
  color: #fff;
}
.headingBox,
.searchBarWrapper {
  padding: 15px 0;
}

.searchBar {
  border: none;
  padding: 10px !important;
  width: 100%;
  background: none;
  font-size: 16px;
}
.searchBar:focus-visible {
  outline: none !important;
}

.inputBar {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  position: relative;
  z-index: 5;
  background-color: white;
}
.searchBarWrapper {
  position: relative;
  transition: 0.5s;
  width: 100%;
}

.searchList {
  z-index: 2 !important;
  position: absolute !important;
  top: 65px;
  left: 0;
  border-radius: 0 0 6px 6px;
  width: 100%;
  box-shadow: 0px 0px 32px -7px rgba(189, 189, 189, 1);
}
