.ql-toolbar {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid inherit !important;
}

.ql-container {
  border: none !important;
}

.textEditorOuter {
  position: relative;
}

.ql-container {
  height: 200px;
}

.imagePickerLayout {
  width: 100px;
  height: 100px;
  position: relative;
}

.imagepickerTemplate {
  width: 100px;
  height: 100px;
  background-color: white;
  box-shadow: none !important;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  flex-direction: column;
}
.imagepickerTemplate:hover {
  background-color: #00a1e5;
  color: white;
}

.closeBtn {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #d32f2f;
  padding: 2px !important;
}

.imagePreview {
  width: 100px;
  height: 100px;
}

.innerCard {
  padding: 15px;
  background-color: #e3e3e3;
  border-radius: 8px;
}
