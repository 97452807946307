.imagePickerLayout {
  width: 100px;
  height: 100px;
  position: relative;
}

.imagePickerLayout .imagepickerTemplate {
  width: 100px;
  height: 100px;
  background-color: white;
  box-shadow: none !important;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  flex-direction: column;
}

.imagePickerLayout .imagepickerTemplate:hover {
  background-color: #00a1e5;
  color: white;
}

.imagePickerLayout .closeBtn {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #d32f2f;
  padding: 2px !important;
}

.imagePickerLayout .imagePreview {
  width: 100px;
  height: 100px;
}
