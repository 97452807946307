.hero {
  background-color: var(--primery);
  position: relative;
  padding: 25px 0px;
}
.buttonItem {
  padding: 30px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lowertextSec1 {
  padding: 10px;
}
.lowertextSec2 {
  padding: 10px;
}

.noteSec {
  padding: 16px;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
/* .buttonItem a {
    margin-right: 20px;
} */
@media (max-width: 768px) {
  .buttonItem {
    padding: 20px;
    flex-direction: column;
  }
  .buttonItem button {
    margin-bottom: 15px;
  }
  .registerForDemoBtn {
    display: none;
  }
}
/* .heroBg { 
   background-image: url('../../../Assets/Images/heroImg.png');
    background-position: center center;
    background-size: cover;
    opacity: 0.9;
    width:100%;
    padding: 50px 0;
    height: 580px;
} */

/*.heroLeftWrapper{
   position: relative;
    top: 100px;
  left: 200px;
}*/
