

.privacyPolicyHeading{
    /* margin: 50px 0 ; */
    margin-bottom: 50px;
    padding: 15px;
    background-color: #5A5B5C;
}

.privacyPolicyHeading a{
    color: #fff !important; 
}
.innerData p{
     margin-bottom: 20px;
     text-align: justify;
     line-height: 1.5;
}
.innerData h5,ul{
margin-bottom: 20px;
}