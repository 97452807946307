
.show_hide {
  display: none;
}
.show {
  display: block;
}
.hide {
  display: block;
}
.menuItemsPrice,
.menuItemsDescount {
  display: flex;
  align-items: center;
}
.menuItemsPrice small {
  color: #ff0000;
  margin-right: 10px;
  text-decoration: line-through;
}
/* .menuItemsPrice h3 {
  color: #575757;
} */
.menuItemsDescount h4 {
  margin-right: 10px;
  color: #3b3b3b;
}
@media screen and (max-width: 480px) {
  .show_hide {
    display: block;
  }
  .hide {
    display: none;
  }
}
