.featuresButtonWrapper {
  height: 100vh;
}
.featureButtons {
  height: 100vh;
  overflow-y: auto;
}

@media (max-width: 900px) {
  .featureButtons,
  .featuresButtonWrapper {
    height: auto;
  }
  .rightContent {
    margin-top: 30px;
  }
  .buttonsWrapper {
    overflow-y: auto !important;
  }
  .featureButtons {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    white-space: nowrap !important;
    margin-bottom: 10px;
  }
}
