footer {
  background-color: var(--primery);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

footer .topFooter {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topFooter button {
  padding: 5px 30px;
  font-size: 12px;
}

footer .MuiCard-root {
  min-height: 420px;
  background-color: var(#fff);
}
.cardHeading span {
  font-weight: 700 !important;
  font-size: 16px !important;
}
.CardsWrapper .card {
  background-color: #f3f3f3;
}

.CardsWrapper a {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 14px;
}
@media (max-width: 900px) {
  footer .topFooter {
    flex-direction: column;
    align-items: center;
  }
  .topFooter p {
    padding-bottom: 14px !important;
  }
}
