.aboutUsHeading {
  margin: 0 0 40px 0;
  padding: 15px;
  background-color: #5a5b5c !important;
}
.aboutUsHeading a {
  color: #fff;
}

.innerData p {
  margin-bottom: 20px;
  text-align: justify;
  line-height: 1.5;
}
