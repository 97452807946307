.login {
  margin-bottom: 60px;
}
.rightSide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightSide img {
  width: 100% !important;
}
.forgotAndRemember {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media (max-width: 900px) {
  .rightSide img {
    display: none !important;
  }

  .logoWrapper {
    top: 58px !important;
    left: 30px !important;
  }
}

@media (max-width: 476px) {
  .forgotAndRemember {
    flex-direction: column;
  }
}
